// Packages
import PropTypes from 'prop-types'
import dayjs from 'dayjs'


// Assets
import './assets/styles.css'


const ListItem = props => {

	// Props
	const { 
		transaction_id, 
		created_at, 
		net, 
		fee, 
		gross, 
		type, 
		gateway 
	} = props

	return (
		<tr>
			<td>{dayjs(created_at).format('DD MMM YYYY HH:mm')}</td>
			<td>{gateway}</td>
			<td>{transaction_id}</td>
			<td className="r">£ {Number(gross).toFixed(2)}</td>
			<td className="r">£ {Number(fee).toFixed(2)}</td>
			<td className="r">£ {Number(net).toFixed(2)}</td>
			<td><div className="OrderTransactionListItem__type">{type}</div></td>
		</tr>
	);
}


ListItem.propTypes = {
	code: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
}

export default ListItem