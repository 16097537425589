// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import ImageUpload from 'components/ImageUpload';


// Components
import Button from 'components/UI/Button';


const PageForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [introduction, setIntroduction] = useState('');
	const [introductionSignOff, setIntroductionSignOff] = useState('');
	const [headerImageUrl, setHeaderImageUrl] = useState();


	useEffect(() => {
		if ( values )
		{
			setIntroduction(values.introduction);
			setIntroductionSignOff(values.introduction_signoff);
			setHeaderImageUrl(values.header_image_url);
		}
	}, [values]);



	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				introduction,
				introduction_signoff: introductionSignOff,
				header_image_url: headerImageUrl
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Introduction text</label>
					<textarea rows="20" onChange={(e) => {
						setIntroduction(e.target.value);
					}} value={introduction} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Introduction Sign-Off Name</label>
					<input onChange={(e) => {
						setIntroductionSignOff(e.target.value);
					}} value={introductionSignOff??''} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Header Image</label>
					<ImageUpload thumbnail={headerImageUrl} onChange={(headerImageUrl) => {
						setHeaderImageUrl(headerImageUrl)
					}}/>
				</div>

			</div>

			<div className="form__footer">
				<Button label="Save Landing Page"/>
			</div>

		</form>
	);
}


PageForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default PageForm;