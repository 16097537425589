// Packages
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const ProgressBar = props => {

	// Props
	const { value } = props;


	return (
		<div className="ProgressBar">

			<div className="ProgressBar__progress" style={{
				width: value + '%'
			}}>

			</div>


		</div>
	);
}

ProgressBar.defaultProps = {
	value: 0
}

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired
}



export default ProgressBar;