// Packages
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'


const ListItem = props => {

	// Props
	const { id, created_at, total, event_id, ambassador } = props;

	return (
		<tr>
			<td><Link to={`/orders/${event_id}/${id}`}>{id}</Link></td>
			<td>{dayjs(created_at).format('DD MMM YYYY HH:mm')}</td>
			<td>{ambassador.firstname} {ambassador.lastname} ({ambassador.id})</td>
			<td>£ {total}</td>
		</tr>
	);
}


ListItem.propTypes = {
	created_at: PropTypes.string.isRequired,
	total: PropTypes.number.isRequired
}

export default ListItem;