// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Components
import Button from 'components/UI/Button';
import ImageUpload from 'components/ImageUpload';


const HotelForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [url, setUrl] = useState('');
	const [milesToVenue, setMilesToVenue] = useState(0);
	const [details, setDetails] = useState('');
	const [photoUrl, setPhotoUrl] = useState();


	useEffect(() => {
		if ( values )
		{
			setName(values.name);
			setAddress(values.address);
			setUrl(values.url);
			setMilesToVenue(values.miles_to_venue);
			setDetails(values.details);
			setPhotoUrl(values.photo_url);
		}
	}, [values]);



	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				name,
				address,
				url,
				miles_to_venue: milesToVenue,
				details,
				photo_url: photoUrl
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Hotel Name</label>
					<input onChange={(e) => {
						setName(e.target.value);
					}} value={name} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Address</label>
					<input onChange={(e) => {
						setAddress(e.target.value);
					}} value={address} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">URL</label>
					<input onChange={(e) => {
						setUrl(e.target.value);
					}} value={url} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Miles To Venue</label>
					<input onChange={(e) => {
						setMilesToVenue(e.target.value);
					}} value={milesToVenue} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Details</label>
					<textarea rows="20" onChange={(e) => {
						setDetails(e.target.value);
					}} value={details} className="form__field__input"/>
				</div>


				<ImageUpload thumbnail={photoUrl} onChange={(publicImageUrl) => {
					setPhotoUrl(publicImageUrl)
				}}/>

			</div>

			<div className="form__footer">
				<Button label="Save Hotel"/>
			</div>

		</form>
	);
}


HotelForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default HotelForm;