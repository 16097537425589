// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import VenueList from 'components/Events/Venues/List';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';


// Other
import Api from 'other/EventsApi';



const VenuesListScreen = () => {

	// State
	const [ venues, setVenues ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		Api.venues.list({
			success: (response) => {
				setVenues(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, []);
	

	return (
		<>
		<ScreenTitle title="Venues"/>

  		<Accordian icon="events" title="Venues" open={true}>
  				
  			{ loading &&
  				<Loading message="Loading venues"/>
  			}

  			{ ! loading &&
  				<VenueList items={venues}/>
  			}
  			
  		</Accordian>


  		<Accordian icon="events" title="Venue Actions">
  			<Button url="/venues/create" label="Create New Venue"/>
  		</Accordian>

  		</>
	)
}

export default VenuesListScreen;