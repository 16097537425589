// Packages
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'


// Components
import Button from 'components/UI/Button'
import Api from 'other/EventsApi'


// Assets
import 'react-datepicker/dist/react-datepicker.css'


const EventForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props


	// State
	const [name, setName] = useState('')
	const [date, setDate] = useState()
	const [time, setTime] = useState('')
	const [venueId, setVenueId] = useState(0)

	const [venues, setVenues] = useState([])
	const [venuesLoading, setVenuesLoading] = useState(true)


	useEffect(() => {
		if ( values )
		{
			setName(values.name);
			setDate(values.date);
			setTime(values.time);
			setVenueId(values.venue_id);
		}
	}, [values]);


	useEffect(() => {

		// Load venues
		Api.venues.list({
			success: (response) => {
				setVenues(response.data);
				setVenuesLoading(false);
			},
			error: (error) => {
				alert('Error loading venues');
			}
		})

	}, [])

	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				name,
				date,
				time,
				venue_id: venueId
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Venue</label>

					{ venuesLoading &&
						<>Loading venues...</>
					}

					{ ! venuesLoading &&
						<select onChange={(e) => {
							setVenueId(e.target.value);
						}} value={venueId} className="form__field__input">
							<option value="0">- Select Venue -</option>
						{venues.map((item) => {

							return (
								<option key={item.id} value={item.id}>{item.name}</option>
							);

						})}
						</select>
					}
				</div>
			
				<div className="form__field">
					<label className="form__field__label">Event Name</label>
					<input onChange={(e) => {
						setName(e.target.value);
					}} value={name} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Date <span className="form__field__label__hint">In the format YYYY-MM-DD</span></label>
					<input onChange={(e) => {
						setDate(e.target.value);
					}} value={date} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Time</label>
					<input onChange={(e) => {
						setTime(e.target.value);
					}} value={time} className="form__field__input"/>
				</div>

			</div>

			<div className="form__footer">
				<Button label="Save Event"/>
			</div>

		</form>
	);
}


EventForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default EventForm;