// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import List from 'components/Events/Orders/List'
import Loading from 'components/UI/Loading'


// Other
import Api from 'other/EventsApi'



const EventOrdersScreen = () => {

	// URL Parameters
	const { eventId } = useParams();


	// State
	const [ event, setEvent ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	
	const [ orders, setOrders] = useState([]);
	const [ ordersLoading, setOrdersLoading] = useState(true);


	const TABS = [
		{ label: 'Overview', url: '/events/' + eventId},
		{ label: 'Sales', url: '/events/' + eventId + '/orders', active: true},
		{ label: 'Tickets', url: '/events/' + eventId + '/tickets'},
		{ label: 'Hotels', url: '/events/' + eventId + '/hotels'},
		{ label: 'FAQs', url: '/events/' + eventId + '/faqs'},
		{ label: 'Landing Page', url: '/events/' + eventId + '/page'}
	];


	useEffect(() => {
		Api.events.get(eventId, {
			success: (response) => {
				setEvent(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		});

		Api.orders.list({
			params: {
				event_id: eventId
			},
			success: (response) => {
				setOrders(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setOrdersLoading(false);
			}
		});
	}, [eventId]);



	if ( loading )
	{
		return <Loading message="Loading event"/>
	}
	

	return (
		<>
		<ScreenTitle title={event.name}/>



  		<Accordian icon="events" title="Orders" open={true} tabs={TABS}>
  				
  			{ ordersLoading &&
  				<Loading message="Loading Orders"/>
  			}

  			{ ! ordersLoading &&
  				<List items={orders}/>
  			}
 			
  			
  		</Accordian>



  		</>
	)
}

export default EventOrdersScreen;