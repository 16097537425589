// Packages
import axios from 'axios';




// Other
import Config from 'other/Config';


/** 
 * Adds defaults to all requests
 */
const setDefaults = (args) => {

    const defaults = {
        // Triggered before request
        before: () => {},
        // Triggered after request (success or error)
        after: () => {},
        // Triggered on a successful request
        success: () => { },
        // If the success has a redirect, use this
        successRedirect: () => {},
        // Triggered on an error
        error: (error) => { alert('An unhandled error occurred: ' + error.message); },
        // Headers
        headers: {},
        // GET parameters
        params: {},
        // POST body data
        data: {},
        // Response type
        responseType: 'json',
        // Bearer token
        bearerToken: true
    };

    for ( const name in defaults )
    {
        if ( ! args[name] )
        {
            args[name] = defaults[name]
        }
    }

    return args;
}




/**
 * The code that actually makes the API calls
 */
const apiRequest = async(method, url, request) => {

    const params = setDefaults(request);

    const token = await getAccessToken();

    params.before();

    if ( url.substring(0, 4) !== 'http' )
    {
        url = Config.API_URL + url;
    }

    if ( ! params.noAuth )
    {
        params.headers.Authorization = 'Bearer ' + token;
    }

    try 
    {
        const response = await axios.request({
            url: url,
            method: method.toLowerCase(),
            params: params.params,
            data: params.data,
            responseType: params.responseType,
            headers: params.headers
        });

        params.success(response.data);
        params.after(response.data);
        params.successRedirect(response.data);
    }
    catch ( exception )
    {
        if ( exception.response !== undefined ) { 

            params.error(exception.response.data);
        }

        params.after();
    }
}




/**	
 * Return the access token from local storage
 */
const getAccessToken = async () => {

	return sessionStorage.getItem('access-token');
}


/**
 * Events
 * ----------------------------------------------
 */
const listEvents = async (request) => {
    apiRequest('get', '/events', request);
}

const getEvent = async (id, request) => {
    apiRequest('get', '/events/' + id, request);
}

const createEvent = async (request) => {
    apiRequest('post', '/events', request);
}

const updateEvent = async (id, request) => {
    apiRequest('put', '/events/' + id, request);
}

const deleteEvent = async (id, request) => {
    apiRequest('delete', '/events/' + id, request);
}


/**
 * Venues
 * ----------------------------------------------
 */
const listVenues = async (request) => {
    apiRequest('get', '/venues', request);
}

const getVenue = async (id, request) => {
    apiRequest('get', '/venues/' + id, request);
}

const createVenue = async (request) => {
    apiRequest('post', '/venues', request);
}

const updateVenue = async (id, request) => {
    apiRequest('put', '/venues/' + id, request);
}

const deleteVenue = async (id, request) => {
    apiRequest('delete', '/venues/' + id, request);
}


/**
 * Ticket Types
 * ----------------------------------------------
 */
const listTicketTypes = async (request) => {
    apiRequest('get', '/tickettypes', request);
}

const getTicketType = async (id, request) => {
    apiRequest('get', '/tickettypes/' + id, request);
}

const createTicketType = async (request) => {
    apiRequest('post', '/tickettypes', request);
}

const updateTicketType = async (id, request) => {
    apiRequest('put', '/tickettypes/' + id, request);
}

const deleteTicketType = async (id, request) => {
    apiRequest('delete', '/tickettypes/' + id, request);
}


/**
 * Hotels
 * ----------------------------------------------
 */
const listHotels = async (request) => {
    apiRequest('get', '/hotels', request);
}

const getHotel = async (id, request) => {
    apiRequest('get', '/hotels/' + id, request);
}

const createHotel = async (request) => {
    apiRequest('post', '/hotels', request);
}

const updateHotel = async (id, request) => {
    apiRequest('put', '/hotels/' + id, request);
}

const deleteHotel = async (id, request) => {
    apiRequest('delete', '/hotels/' + id, request);
}


/**
 * FAQs
 * ----------------------------------------------
 */
const listFaqs = async (request) => {
    apiRequest('get', '/faqs', request);
}

const getFaq = async (id, request) => {
    apiRequest('get', '/faqs/' + id, request);
}

const createFaq = async (request) => {
    apiRequest('post', '/faqs', request);
}

const updateFaq = async (id, request) => {
    apiRequest('put', '/faqs/' + id, request);
}

const deleteFaq = async (id, request) => {
    apiRequest('delete', '/faqs/' + id, request);
}


/**
 * Orders
 * ----------------------------------------------
 */
const listOrders = async (request) => {
    apiRequest('get', '/orders', request);
}

const getOrder = async (id, request) => {
    apiRequest('get', '/orders/' + id, request);
}

const createOrder = async (request) => {
    apiRequest('post', '/orders', request);
}

const updateOrder = async (id, request) => {
    apiRequest('put', '/orders/' + id, request);
}

const deleteOrder = async (id, request) => {
    apiRequest('delete', '/orders/' + id, request);
}

const sendOrderEmail = async (id, email, request = {}) => {
    request.data = {email}
    apiRequest('post', '/orders/' + id + '/email-acknowledgement', request);
}

const exportOrders = async (eventId, request = {}) => {
    apiRequest('get', '/orders/export/' + eventId, request);
}

const getOrderTransactions = async (id, request) => {
    apiRequest('get', '/orders/' + id + '/transactions', request);
}

const getOrderRefunds = async (id, request) => {
    apiRequest('get', '/orders/' + id + '/refunds', request);
}

const createOrderRefund = async (id, request) => {
    apiRequest('post', '/orders/' + id + '/refunds', request);
}

/**
 * Coupons
 * ----------------------------------------------
 */
const listCoupons = async (request) => {
    apiRequest('get', '/coupons', request);
}

const getCoupon = async (id, request) => {
    apiRequest('get', '/coupons/' + id, request);
}

const createCoupon = async (request) => {
    apiRequest('post', '/coupons', request);
}

const updateCoupon = async (id, request) => {
    apiRequest('put', '/coupons/' + id, request);
}

const deleteCoupon = async (id, request) => {
    apiRequest('delete', '/coupons/' + id, request);
}


/**
 * Refunds
 * ----------------------------------------------
 */
const listRefunds = async (request) => {
    apiRequest('get', '/refunds', request);
}

const getRefund = async (id, request) => {
    apiRequest('get', '/refunds/' + id, request);
}

const sendRefundEmail = async (id, email, request = {}) => {
    request.data = {email}
    apiRequest('post', '/refunds/' + id + '/email-acknowledgement', request);
}



const uploadFile = async (file, request) => {

    apiRequest('post', '/uploads/get-signed-url', {
        data: {
            fileName: file.name,
            contentType: file.type
        },
        success: async (response) => {
            try
            {
                await axios.put(response.data.url, file, {
                  headers: {
                    'content-type': file.type
                  },
                  onUploadProgress: (e) => {
                    var percentCompleted = Math.round((e.loaded * 100) / e.total);
                    if ( request.progress )
                    {
                        request.progress(percentCompleted);
                    }
                  }
                });

                request.success(response.data)
            }
            catch ( exception )
            {
                request.error(exception);
            }
        }
    });
}


const output = {
    events: {
        list: listEvents,
        get: getEvent,
        create: createEvent,
        update: updateEvent,
        delete: deleteEvent
    },
    ticketTypes: {
        list: listTicketTypes,
        get: getTicketType,
        create: createTicketType,
        update: updateTicketType,
        delete: deleteTicketType
    },
    venues: {
        list: listVenues,
        get: getVenue,
        create: createVenue,
        update: updateVenue,
        delete: deleteVenue
    },
    hotels: {
        list: listHotels,
        get: getHotel,
        create: createHotel,
        update: updateHotel,
        delete: deleteHotel
    },
    faqs: {
        list: listFaqs,
        get: getFaq,
        create: createFaq,
        update: updateFaq,
        delete: deleteFaq
    },
    orders: {
        list: listOrders,
        get: getOrder,
        create: createOrder,
        update: updateOrder,
        delete: deleteOrder,
        sendEmail: sendOrderEmail,
        export: exportOrders,
        getTransactions: getOrderTransactions,
        getRefunds: getOrderRefunds,
        createRefund: createOrderRefund
    },
    coupons: {
        list: listCoupons,
        get: getCoupon,
        create: createCoupon,
        update: updateCoupon,
        delete: deleteCoupon
    },
    refunds: {
        list: listRefunds,
        get: getRefund,
        sendEmail: sendRefundEmail,
    },

    uploadFile
};

export default output;
