// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Components
import Button from 'components/UI/Button';


const CouponForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	const [type, setType] = useState('');
	const [value, setValue] = useState('');


	useEffect(() => {
		if ( values )
		{
			setName(values.name);
			setCode(values.code);
			setType(values.type);
			setValue(values.value);
		}
	}, [values]);



	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				name,
				code,
				type,
				value
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Name</label>
					<input onChange={(e) => {
						setName(e.target.value);
					}} value={name} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Code</label>
					<input onChange={(e) => {
						setCode(e.target.value);
					}} value={code} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Type</label>
					<select onChange={(e) => {
						setType(e.target.value);
					}} value={type} className="form__field__input">
						<option value="">- Select Coupon Type -</option>
						<option value="fixed">Fixed Value</option>
						<option value="percentage">Percentage Value</option>
						<option value="foc">Free of Charge</option>
					</select>	
				</div>

				<div className="form__field">
					<label className="form__field__label">Value</label>
					<input onChange={(e) => {
						setValue(e.target.value);
					}} value={value} className="form__field__input"/>
				</div>

			</div>

			<div className="form__footer">
				<Button label="Save Coupon"/>
			</div>

		</form>
	);
}


CouponForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default CouponForm;