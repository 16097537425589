// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import SelectEventScreen from 'screens/Refunds/SelectEvent'
import ListScreen from 'screens/Refunds/List'
import ViewScreen from 'screens/Refunds/View'


// Layouts
import DefaultLayout from 'layouts/Default'


const RefundRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<SelectEventScreen/>}/>
				<Route exact path="/:eventId" element={<ListScreen/>}/>
				<Route exact path="/:eventId/:refundId" element={<ViewScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default RefundRoutes