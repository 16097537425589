// Packages
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Form from 'components/Events/Hotels/Form'


// Other
import Api from 'other/EventsApi'



const EventHotelsCreateScreen = () => {

	// URL Parameters
	const { eventId } = useParams();


	// State
	const [ event, setEvent ] = useState([]);
	const [ eventLoading, setEventLoading ] = useState(true);

	const [ saving, setSaving ] = useState(false);

	const [error, setError] = useState();

	const navigate = useNavigate();


	const TABS = [
		{ label: 'Overview', url: '/events/' + eventId},
		{ label: 'Sales', url: '/events/' + eventId + '/orders'},
		{ label: 'Tickets', url: '/events/' + eventId + '/tickets'},
		{ label: 'Hotels', url: '/events/' + eventId + '/hotels', active: true},
		{ label: 'FAQs', url: '/events/' + eventId + '/faqs'},
		{ label: 'Landing Page', url: '/events/' + eventId + '/page'}
	];


	useEffect(() => {
		Api.events.get(eventId, {
			success: (response) => {
				setEvent(response);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setEventLoading(false);
			}
		});

		
	}, [eventId]);


	const onSubmit = (values) => {

		Api.hotels.create({

			data: {event_id: eventId, ...values},

			before: () => {
				setSaving(true);
			},
			successRedirect: (response) => {
				navigate('/events/' + eventId + '/hotels');
			},
			error: (error) => {
				setError(error.error);
			},
			after: () => {
				setSaving(false);
			},
		})

	}



	if ( eventLoading )
	{
		return <Loading message="Loading Event"/>
	}
	

	return (
		<>
		<ScreenTitle title={event.name}/>



  		<Accordian icon="events" title="Overview" open={true} tabs={TABS}>

  			<Form errorMessage={error} onSubmit={(values) => {
				onSubmit(values);
			}}/>

		{ saving &&
			<>
			<br/>
			<Loading message="Saving hotel..."/>
			</>
		}
  			
  			
  		</Accordian>



  		</>
	)
}

export default EventHotelsCreateScreen;