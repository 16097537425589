// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Components
import Button from 'components/UI/Button';



const VenueForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');


	useEffect(() => {
		if ( values )
		{
			setName(values.name);
			setAddress(values.address);
		}
	}, [values]);



	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				name,
				address
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Name</label>
					<input onChange={(e) => {
						setName(e.target.value);
					}} value={name} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Address</label>
					<input onChange={(e) => {
						setAddress(e.target.value);
					}} value={address} className="form__field__input"/>
				</div>

			</div>

			<div className="form__footer">
				<Button label="Save Venue"/>
			</div>

		</form>
	);
}


VenueForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default VenueForm;