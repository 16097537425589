// Packages
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { name, date, id, capacity, sold } = props;

	return (
		<tr>
			<td><Link to={`/events/${id}`}>{dayjs(date).format('ddd D MMMM YYYY')}</Link></td>
			<td>{name}</td>
			<td>{capacity}</td>
			<td>{sold}</td>
		</tr>
	);
}


ListItem.propTypes = {
	date: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
}

export default ListItem;