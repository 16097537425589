// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'


// Other
import Api from 'other/EventsApi'



const OrdersCsvScreen = () => {

	// URL Params
	const { eventId } = useParams()


	// State
	const [ event, setEvent ] = useState([])
	const [ eventLoading, setEventLoading ] = useState(true)

	const [ data, setData ] = useState()
	const [ dataLoading, setDataLoading ] = useState(false)

	useEffect(() => {
		Api.events.get(eventId, {
			success: (response) => {
				setEvent(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setEventLoading(false);
			}
		})
	}, [eventId]);


	const downloadExport = () => {

		Api.orders.export(eventId, {
			before: () => {
				setDataLoading(true)	
			},
			success: (response) => {
				setData(response.data)
			},
			error: (error) => {
				alert('error')
			},
			after: () => {
				setDataLoading(false)
			}
		})
		
	}



	if ( eventLoading )
	{
		return (
			<Loading message="Loading event"/>
		)
	}
	

	return (
		<>
		<ScreenTitle title={`Export Ticket Sales for ${event.name}`}/>

  		<Accordian icon="tickets" title="Download CSV" open={true}>

  			<p>Click on the following button to download all ticket sales in CSV format. It may take a few minutes to generate the downloadable file.</p>
  				
  			{ dataLoading &&
  				<Loading message="Preparing Export File"/>
  			}

  			{ data &&
  				<CSVLink className="Button Button--colour-orange" filename={`${event.name} Export.csv`} data={data}>Download CSV File</CSVLink>
  			}

  			{ ! dataLoading && ! data &&
  				<Button onClick={downloadExport} label="Generate CSV File"/>
  			}

  		</Accordian>


  		</>
	)
}

export default OrdersCsvScreen;