// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Events/List'
import ViewScreen from 'screens/Events/View';
import CreateScreen from 'screens/Events/Create';
import EditScreen from 'screens/Events/Edit';

import TicketTypesScreen from 'screens/Events/View/TicketTypes';
import TicketTypesCreateScreen from 'screens/Events/View/TicketTypes/Create';
import TicketTypesEditScreen from 'screens/Events/View/TicketTypes/Edit';

import HotelsScreen from 'screens/Events/View/Hotels';
import HotelsCreateScreen from 'screens/Events/View/Hotels/Create';
import HotelsEditScreen from 'screens/Events/View/Hotels/Edit';

import FaqsScreen from 'screens/Events/View/Faqs';
import FaqsCreateScreen from 'screens/Events/View/Faqs/Create';
import FaqsEditScreen from 'screens/Events/View/Faqs/Edit';


import PageEditScreen from 'screens/Events/View/Page/Edit';




import OrderListScreen from 'screens/Events/View/Orders/List'


// Layouts
import DefaultLayout from 'layouts/Default'


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:eventId" element={<ViewScreen/>}/>
				<Route exact path="/:eventId/edit" element={<EditScreen/>}/>

				<Route exact path="/:eventId/tickets" element={<TicketTypesScreen/>}/>
				<Route exact path="/:eventId/tickets/create" element={<TicketTypesCreateScreen/>}/>
				<Route exact path="/:eventId/tickets/:ticketTypeId" element={<TicketTypesEditScreen/>}/>
			
				<Route exact path="/:eventId/hotels" element={<HotelsScreen/>}/>
				<Route exact path="/:eventId/hotels/create" element={<HotelsCreateScreen/>}/>
				<Route exact path="/:eventId/hotels/:hotelId" element={<HotelsEditScreen/>}/>

				<Route exact path="/:eventId/faqs" element={<FaqsScreen/>}/>
				<Route exact path="/:eventId/faqs/create" element={<FaqsCreateScreen/>}/>
				<Route exact path="/:eventId/faqs/:faqId" element={<FaqsEditScreen/>}/>

				<Route exact path="/:eventId/orders" element={<OrderListScreen/>}/>

				<Route exact path="/:eventId/page" element={<PageEditScreen/>}/>

			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes