// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import CouponListScreen from 'screens/Coupons/List';
import CouponCreateScreen from 'screens/Coupons/Create';
import CouponEditScreen from 'screens/Coupons/Edit';


// Layouts
import DefaultLayout from 'layouts/Default'


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<CouponListScreen/>}/>
				<Route exact path="/create" element={<CouponCreateScreen/>}/>
				<Route exact path="/:couponId" element={<CouponEditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes