// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import RefundList from 'components/Refunds/List'
import Loading from 'components/UI/Loading'


// Other
import Api from 'other/EventsApi'



const RefundsListScreen = () => {

	// URL Params
	const { eventId } = useParams();


	// State
	const [ refunds, setRefunds ] = useState([]);
	const [ refundsLoading, setRefundsLoading ] = useState(true);

	useEffect(() => {
		Api.refunds.list({
			params: {
				event_id: eventId
			},
			success: (response) => {
				setRefunds(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setRefundsLoading(false);
			}
		})
	}, [eventId]);
	

	return (
		<>
		<ScreenTitle title="Refunds"/>

  		<Accordian icon="events" title="Refunds" open={true}>
  				
  			{ refundsLoading &&
  				<Loading message="Loading refunds"/>
  			}

  			{ ! refundsLoading &&
  				<RefundList items={refunds}/>
  			}
  			
  		</Accordian>

  		</>
	)
}

export default RefundsListScreen;