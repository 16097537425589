// Packages
import PropTypes from 'prop-types';


// Components
import ListItem from 'components/Events/Coupons/ListItem';


const CouponList = props => {

    // Props
    const { items } = props;

    return (
        <table className="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Code</th>
                <th>Type</th>
                <th>Value</th>
			</tr>
			</thead>
			<tbody>


            {items.map((item) => {

                return (
                    <ListItem key={item.id} {...item}/>
                );

            })}

            </tbody>

        </table>
    );
}


CouponList.propTypes = {
    items: PropTypes.array.isRequired
}

export default CouponList;