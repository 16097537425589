// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import OrderList from 'components/Events/Orders/List'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'


// Other
import Api from 'other/EventsApi'



const OrdersListScreen = () => {

	// URL Params
	const { eventId } = useParams();


	// State
	const [ orders, setOrder ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		Api.orders.list({
			params: {
				event_id: eventId
			},
			success: (response) => {
				setOrder(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, [eventId]);
	

	return (
		<>
		<ScreenTitle title="Orders"/>

  		<Accordian icon="events" title="Orders" open={true}>
  				
  			{ loading &&
  				<Loading message="Loading orders"/>
  			}

  			{ ! loading &&
  				<OrderList items={orders}/>
  			}
  			
  		</Accordian>


  		<Accordian icon="events" title="Order Actions">
  			<Button url={`/orders/${eventId}/export`} label="Export Ticket Data"/>
  		</Accordian>

  		</>
	)
}

export default OrdersListScreen;