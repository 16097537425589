// Packages
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Loading from 'components/UI/Loading'


// Other
import Api from 'other/EventsApi';



const OrdersListScreen = () => {

	// State
	const [ events, setEvents ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		Api.events.list({
			success: (response) => {
				setEvents(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, []);
	

	return (
		<>
		<ScreenTitle title="Select Event to view orders"/>

		{ loading &&
  				<Loading message="Loading events"/>
  			}

		{events.map((event) => {

			return (
				<div key={event.id}>
					<Link to={`/orders/${event.id}`}>
					{event.name}
					</Link>
				</div>
			)

		})}


  		</>
	)
}

export default OrdersListScreen;