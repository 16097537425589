// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import VenueListScreen from 'screens/Venues/List'
import VenueCreateScreen from 'screens/Venues/Create';
import VenueEditScreen from 'screens/Venues/Edit';


// Layouts
import DefaultLayout from 'layouts/Default'


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<VenueListScreen/>}/>
				<Route exact path="/create" element={<VenueCreateScreen/>}/>
				<Route exact path="/:venueId" element={<VenueEditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes