// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { name, price, id, enabled, capacity, sold, event_id: eventId } = props;

	return (
		<tr>
			<td><Link to={`/events/${eventId}/tickets/${id}`}>{name}</Link>

			{ ! enabled &&
				<span className="colour--red small" style={{marginLeft: '20px'}}>Disabled</span>
			}

			</td>
			<td>£ {Number(price).toFixed(2)}</td>
			<td>{capacity}</td>
			<td>{sold}</td>
		</tr>
	);
}


ListItem.propTypes = {
	price: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	event_id: PropTypes.string.isRequired
}

export default ListItem;