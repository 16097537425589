// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Components
import Button from 'components/UI/Button';


const FaqForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');


	useEffect(() => {
		if ( values )
		{
			setQuestion(values.question);
			setAnswer(values.answer);
		}
	}, [values]);



	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				question,
				answer
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<div className="form__field">
					<label className="form__field__label">Question</label>
					<textarea rows="5" onChange={(e) => {
						setQuestion(e.target.value);
					}} value={question} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Answer</label>
					<textarea rows="10" onChange={(e) => {
						setAnswer(e.target.value);
					}} value={answer} className="form__field__input"/>
				</div>

				

			</div>

			<div className="form__footer">
				<Button label="Save FAQ"/>
			</div>

		</form>
	);
}


FaqForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
}

export default FaqForm;