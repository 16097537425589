// Components
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'


// Assets
import './styles.css'



const DefaultLayout = props => {

	return (
		<div className="DefaultLayout">
			<Sidebar/>
			<div className="DefaultLayout__header">
				<Header/>
			</div>
			<div className="DefaultLayout__body">
				{props.children}
			</div>		
		</div>
	)
}


export default DefaultLayout