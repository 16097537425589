

const DashboardScreen = () => {

	return (
		<>
			<h1>Welcome to the Tropic Events Admin Portal.</h1>

			<p>x</p>
  		</>
	)
}

export default DashboardScreen;