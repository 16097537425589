// Packages
import PropTypes from 'prop-types';


// Components
import ListItem from 'components/Events/TicketTypes/ListItem';




const EventTicketTypeList = props => {

    // Props
    const { items } = props;

    return (
        <table className="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Price</th>
                <th>Total Capacity</th>
                <th>Sold</th>
			</tr>
			</thead>
			<tbody>


            {items.map((item) => {

                return (
                    <ListItem key={item.id} {...item}/>
                );

            })}

            </tbody>

        </table>
    );
}


EventTicketTypeList.propTypes = {
    items: PropTypes.array.isRequired
}

export default EventTicketTypeList;