// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import EventList from 'components/Events/List';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';


// Other
import EventsApi from 'other/EventsApi';



const EventsListScreen = () => {

	// State
	const [ events, setEvents ] = useState([]);
	const [ loading, setLoading ] = useState(true);


	useEffect(() => {
		EventsApi.events.list({
			success: (response) => {
				setEvents(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, []);
	

	return (
		<>
		<ScreenTitle title="Events"/>

  		<Accordian icon="events" title="Events" open={true}>
  				
  			{ loading &&
  				<Loading message="Loading events"/>
  			}

  			{ ! loading &&
  				<EventList items={events}/>
  			}
  			
  		</Accordian>


  		<Accordian icon="events" title="Event Actions">
  			<Button url="/events/create" label="Create New Event"/>
  		</Accordian>

  		</>
	)
}

export default EventsListScreen;