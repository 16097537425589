

// Assets
import './styles.css';


import Tooltip from 'components/UI/Tooltip';


const Table = props => {

	return (
		<div className="InfoTable">
			{props.children}
		</div>
	);
}


const Row = props => {

	return (
		<div className="InfoTable__Row">
			{props.children}
		</div>
	);
}

const Column = props => {

	const { label, emptyValue, help } = props;
	let { value } = props;

	let valueClass = '';

	if ( ( value === '' || value === undefined ) && props.children === null )
	{
		value = emptyValue;
		valueClass = 'InfoTable__Column__value__empty';
	}

	return (
		<div className="InfoTable__Column">
			<div className="InfoTable__Column__label">
				{label}
			</div>
			<div className="InfoTable__Column__value">
				<div className={valueClass}>{value} {props.children}</div>
				{ help &&
					<Tooltip help={help}/>
				}
			</div>
		</div>
	);

}

Column.defaultProps = {
	emptyValue: ''
}

const _export = {
	Table,
	Row,
	Column
};

export default _export;