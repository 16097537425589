// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { id, question, answer, event_id: eventId } = props;

	return (
		<tr>
			<td><Link to={`/events/${eventId}/faqs/${id}`}>{question}</Link></td>
			<td>{answer}</td>
		</tr>
	);
}


ListItem.propTypes = {
	question: PropTypes.string.isRequired,
	answer: PropTypes.string.isRequired,
	event_id: PropTypes.string.isRequired
}

export default ListItem;