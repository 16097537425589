// Packages
import PropTypes from 'prop-types';


// Components
import ListItem from 'components/Events/Faqs/ListItem';


const FaqList = props => {

    // Props
    const { items } = props;

    return (
        <table className="table">
			<thead>
			<tr>
				<th>Question</th>
                <th>Answer</th>
			</tr>
			</thead>
			<tbody>

            {items.map((item) => {

                return (
                    <ListItem key={item.id} {...item}/>
                );

            })}

            </tbody>

        </table>
    );
}


FaqList.propTypes = {
    items: PropTypes.array.isRequired
}

export default FaqList;