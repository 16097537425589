// Packages
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Events/Form';
import Loading from 'components/UI/Loading';


// Other
import EventsApi from 'other/EventsApi';


const EventCreateScreen = () => {

	// State
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const navigate = useNavigate();
	

	const onSubmit = (values) => {

		EventsApi.events.create({

			data: values,

			before: () => {
				setLoading(true);
			},
			successRedirect: (response) => {
				navigate('/events/' + response.data.id);
			},
			error: (error) => {
				setError(error.error);
			},
			after: () => {
				setLoading(false);
			},
		})

	}
	

	return (
		<>

		<ScreenTitle title="Create New Event" breadcrumb={[
			{label: 'Events', url: '/events'}
		]}/>

		<Form errorMessage={error} onSubmit={(values) => {
			onSubmit(values);
		}}/>

		{ loading &&
			<>
			<br/>
			<Loading message="Saving event..."/>
			</>
		}
  		</>
	)
}

export default EventCreateScreen