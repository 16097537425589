// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import SelectEventScreen from 'screens/Orders/SelectEvent'

import ListScreen from 'screens/Orders/List'
import ViewScreen from 'screens/Orders/View'
import TicketNamesScreen from 'screens/Orders/TicketNames'
import ExportScreen from 'screens/Orders/Export'
import RefundScreen from 'screens/Orders/Refund'

// import CreateScreen from 'screens/Events/Create';
// import EditScreen from 'screens/Events/Edit';




// import OrderListScreen from 'screens/Events/View/Orders/List'


// Layouts
import DefaultLayout from 'layouts/Default'


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<SelectEventScreen/>}/>
				<Route exact path="/:eventId" element={<ListScreen/>}/>
				<Route exact path="/:eventId/:orderId" element={<ViewScreen/>}/>
				<Route exact path="/:eventId/:orderId/ticket_names" element={<TicketNamesScreen/>}/>
				<Route exact path="/:eventId/:orderId/refund" element={<RefundScreen/>}/>
				<Route exact path="/:eventId/export" element={<ExportScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes