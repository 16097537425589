// Packages
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Events/Form';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

// Other
import Api from 'other/EventsApi';


const EventEditScreen = () => {

	// URL Params
	const { eventId } = useParams();


	// State
	const [event, setEvent] = useState();
	const [eventLoading, setEventLoading] = useState(false);

	const [saving, setSaving] = useState(false)
	
	const [error, setError] = useState();

	const navigate = useNavigate();


	useEffect(() => {

		Api.events.get(eventId, {
			success: (response) => {
				setEvent(response.data);
			},
			error: (error) => {
				alert('Error loading event');
			},
			after: () => {
				setEventLoading(false);
			}
		})

	}, [eventId])
	

	const onSubmit = (values) => {

		Api.events.update(eventId, {

			data: values,

			before: () => {
				setSaving(true);
			},
			successRedirect: (response) => {
				navigate('/events/' + response.data.id);
			},
			error: (error) => {
				setError(error.error);
			},
			after: () => {
				setSaving(false);
			},
		})

	}

	const onDelete = () => {
		Api.events.delete(eventId, {
			successRedirect: () => {
				navigate('/events');
			},
			error: (error) => {
				alert('Error deleting event');
			}

		});
	}


	if ( eventLoading )
	{
		return <Loading message="Loading event"/>
	}
	

	return (
		<>

		<ScreenTitle title="Edit Event" breadcrumb={[
			{label: 'Events', url: '/events'},

		]}/>

		<Form values={event} errorMessage={error} onSubmit={(values) => {
			onSubmit(values);
		}}/>

		{ saving &&
			<>
			<br/>
			<Loading message="Saving event..."/>
			</>
		}

		<Button onClick={() => {

			if ( window.confirm('Really delete this event?') )
			{
				onDelete();
			}


		}} colour="red" style={{marginTop: '50px'}} label="Delete Event"/>
  			
  		</>
	)
}

export default EventEditScreen