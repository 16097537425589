// Packages
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'


// Assets
import './assets/styles.css'


const ListItem = props => {

	// Props
	const { id, created_at, total, event_id, ambassador, status } = props;

	return (
		<tr>
			<td><Link to={`/refunds/${event_id}/${id}`}>{id}</Link></td>
			<td>{dayjs(created_at).format('DD MMM YYYY HH:mm')}</td>
			<td>{ambassador.firstname} {ambassador.lastname} ({ambassador.id})</td>
			<td>£ {total}</td>
			<td><div className={`RefundListItem__type RefundListItem__type--${status}`}>{status}</div></td>
		</tr>
	);
}


ListItem.propTypes = {
	created_at: PropTypes.string.isRequired,
	total: PropTypes.number.isRequired
}

export default ListItem;