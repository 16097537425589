// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'
import InfoTable from 'components/UI/InfoTable'
import SendPurchaseEmail from 'components/Events/Orders/SendPurchaseEmail'
import TransactionList from 'components/Events/Orders/TransactionList'
import RefundList from 'components/Refunds/List'


// Other
import Api from 'other/EventsApi'



const OrdersViewScreen = () => {

	// URL Params
	const { eventId, orderId } = useParams();


	// State
	const [ order, setOrder ] = useState([]);
	const [ orderLoading, setOrderLoading ] = useState(true);

	const [ refunds, setRefunds ] = useState([]);
	const [ refundsLoading, setRefundsLoading ] = useState(true);

	useEffect(() => {
		Api.orders.get(orderId, {
			success: (response) => {
				setOrder(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setOrderLoading(false);
			}
		})

		Api.orders.getRefunds(orderId, {
			success: (response) => {
				setRefunds(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setRefundsLoading(false);
			}
		})
	}, [orderId]);
	


	if ( orderLoading )
	{
		return (
			<Loading message="Loading order"/>
		)
	}

	return (
		<>
		<ScreenTitle title={`Order ${order.id}`}/>

  		<Accordian icon="user" title="Order Overview" open={true}>
  				
  			<InfoTable.Table>

  				<InfoTable.Row>
					<InfoTable.Column label="Order #" value={order.id}/>
					<InfoTable.Column label="Order Date" value={dayjs(order.created_at).format('DD MMM YYYY HH:mm')}/>
				</InfoTable.Row>

				<InfoTable.Row>
					<InfoTable.Column label="Ambassador Name" value={order.ambassador.firstname + ' ' + order.ambassador.lastname}/>
					<InfoTable.Column label="Ambassador #" value={order.ambassador.id}/>
				</InfoTable.Row>
				<InfoTable.Row>
					<InfoTable.Column label="Email" value={order.ambassador.email}/>
					<InfoTable.Column label="Telephone" value={order.ambassador.telephone}/>
				</InfoTable.Row>


  			</InfoTable.Table>
  			
  		</Accordian>


  		<Accordian icon="tickets" title="Tickets" open={true}>

  			<table className="table">
  			<thead>
  			<tr>
  				<th>#</th>
  				<th>Ticket</th>
  				<th>Attendee Name</th>
  				<th>Dietary Requirements</th>
  				<th>Allergies</th>
  				<th>Accessibility</th>
  				<th className="r">Price</th>
  			</tr>
  			</thead>
  			<tbody>

  			{order.tickets.map((ticket, index) => {

  				return (
  					<tr key={index} className={ticket.status === 'refunded' ? 'red': ''}>
  						<td style={{width: '40px'}}>{index+1}</td>
  						<td>
  							<b>{ticket.ticket_type.name}</b><br/>
  							{ticket.event.name}
  						</td>
  						<td>{ticket.attendee_name}</td>
  						<td>{ticket.dietary_requirements &&

  							ticket.dietary_requirements.map((item) => {
  								return (
  									<div key={item}>{item}</div>
  								)
  							})

  						}
  						</td>
  						<td>{ticket.allergies}</td>
  						<td>{ticket.accessibility_requirements}</td>
  						<td className="r">

  							{ ticket.status === 'refunded' &&
  								<div className="refunded">Refunded</div>
  							}

  							{ ticket.status !== 'refunded' &&

  								<>
  								£ {Number(ticket.ticket_type.price).toFixed(2)}
  								</>
  							}

  						</td>
  					</tr>
  				)

  			})}

  			</tbody>

  			<tfoot>

  			{ order.coupon &&
  				<>
  					<tr>
	  					<td colSpan="6" className="r"><b>Sub-Total:</b></td>
	  					<td className="r"><b>£ {Number(order.sub_total).toFixed(2)}</b></td>
	  				</tr>

  					<tr>
	  					<td colSpan="6" className="r"><b>Coupon {order.coupon.code} Discount:</b></td>
	  					<td className="r"><b>£ {Number(order.discount).toFixed(2)}</b></td>
	  				</tr>

  				</>
  			}
  			
  			<tr>
  				<td colSpan="6" className="r"><b>Total:</b></td>
  				<td className="r"><b>£ {Number(order.total).toFixed(2)}</b></td>
  			</tr>
  			<tr style={{color: '#76B82A'}}>
  				<td colSpan="6" className="r"><b>Total Paid:</b></td>
  				<td className="r"><b>£ {Number(order.total_paid).toFixed(2)}</b></td>
  			</tr>
  			<tr style={{color: '#BC2354'}}>
  				<td colSpan="6" className="r"><b>Total Due:</b></td>
  				<td className="r"><b>£ {Number(order.total_due).toFixed(2)}</b></td>
  			</tr>
  			</tfoot>

  			</table>

  			<Button url={`/orders/${eventId}/${orderId}/ticket_names`} label="Change Ticket Details"/>


  		</Accordian>


  		<Accordian icon="financial" title="Financial Transactions">

 				<TransactionList items={order.transactions}/>	
  		</Accordian>

  		<Accordian icon="financial" title="Refunds">

  			{ ! refundsLoading &&
  				<RefundList items={refunds}/>
  			}

  			<Button url={`/orders/${eventId}/${orderId}/refund`} colour="blue" label="Start A Refund"/>

  		</Accordian>


  		<Accordian icon="email" title="Resend Email">
  			<p>Use this feature to resend the initial purchase acknowledgement email. If the Ambassador is having problems with their primary email account, then you can enter an alternative email address in the box below.</p>
	  		<SendPurchaseEmail email={order.ambassador.email} orderId={order.id}/>
  		</Accordian>

  		</>
	)
}

export default OrdersViewScreen;