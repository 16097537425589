// Libraries
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


import Api from 'other/EventsApi';


const SendRefundEmail = props => {
	

	// Props
	const { refundId, email } = props


	// State
	const [ targetEmail, setTargetEmail ] = useState()
	const [ loading, setLoading ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState('')
	const [ successMessage, setSuccessMessage ] = useState('')


	useEffect(() => {
		setTargetEmail(email)
	}, [email])


	const submit = async () => {

		if ( targetEmail === '' ) 
		{
			setErrorMessage('Enter an email address above');
			return false;
		}

		


		Api.refunds.sendEmail(refundId, targetEmail, {
			before: () => {
				setLoading(true);
				setSuccessMessage('');
				setErrorMessage('');
			},
			success: (response) => {
				setSuccessMessage(response.data);
			},
			error: (error) => {
				console.log(' ** EXCEPTION **', error);
				setErrorMessage(error.error);
			},
			after: () => {
				setLoading(false);
			}

		});
	}

	return (
		<div className="SendWelcomeEmail">

			{ loading &&
				<Loading message="Sending email, please wait..."/>
			}	


			{ ! loading &&

				<form onSubmit={(e) => {
					e.preventDefault();
					submit();
				}}>

				<input type="text" value={targetEmail} onChange={(e) => {
						setTargetEmail(e.target.value);
					}} className="input" placeholder="Enter email address"/>

  				<Button style={{marginLeft: '10px'}} label="Send Email"/>

				</form>
			}

			{ successMessage !== '' &&
  				<Message type="ok" text={successMessage}/>
  			}


  			{ errorMessage !== '' &&
  				<Message type="error" text={errorMessage}/>
  			}

		</div>
	);
}


// Prop Types
SendRefundEmail.propTypes = {
	refundId: PropTypes.string.isRequired
}

export default SendRefundEmail;