// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import CouponList from 'components/Events/Coupons/List';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';


// Other
import Api from 'other/EventsApi';



const CouponListScreen = () => {

	// State
	const [ coupons, setCoupons ] = useState([]);
	const [ loading, setLoading ] = useState(true);


	useEffect(() => {
		Api.coupons.list({
			success: (response) => {
				setCoupons(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, []);
	

	return (
		<>
		<ScreenTitle title="Discount Coupons"/>

  		<Accordian icon="events" title="Coupons" open={true}>
  				
  			{ loading &&
  				<Loading message="Loading coupons"/>
  			}

  			{ ! loading &&
  				<CouponList items={coupons}/>
  			}
  			
  		</Accordian>


  		<Accordian icon="events" title="Coupon Actions">
  			<Button url="/coupons/create" label="Create New Coupon"/>
  		</Accordian>

  		</>
	)
}

export default CouponListScreen;