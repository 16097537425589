// Packages
import { Routes, Route } from "react-router-dom"


// Routes
import MiscRoutes from 'routes/misc'
import EventRoutes from 'routes/events'
import OrdersRoutes from 'routes/orders'
import CouponRoutes from 'routes/coupons'
import VenueRoutes from 'routes/venues'
import RefundRoutes from 'routes/refunds'


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/events/*" element={<EventRoutes/>}/>
			<Route path="/coupons/*" element={<CouponRoutes/>}/>
			<Route path="/venues/*" element={<VenueRoutes/>}/>
			<Route path="/orders/*" element={<OrdersRoutes/>}/>
			<Route path="/refunds/*" element={<RefundRoutes/>}/>

			<Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	);
}


export default MainRoutes;