// Packages
import PropTypes from 'prop-types'
import { useState, useEffect, useMemo } from 'react'


// Assets
import './assets/styles.css'


const DietarySelector = props => {

	// Props
	const { values, onChange } = props;


	// State
	const [selected, setSelected] = useState([]);
	const [otherSelected, setOtherSelected] = useState(false)
    const [other, setOther] = useState()


    const OPTIONS = useMemo(() => {
    	return [
			'No Dietary Requirements',
			'Vegetarian',
			'Vegan',
			'Gluten Free',
			'Dairy Free'
		]
	}, [])



	useEffect(() => {
		setSelected(values)

		for ( var i = 0; i < values.length; i++ )
        {
            if ( OPTIONS.indexOf(values[i]) === -1 && values[i] !== '' )
            {
                setOtherSelected(true);
                setOther(values[i])
            }
        }
	}, [values, OPTIONS])

	
	


	const addOption = (option) => {
		let newSelected = [...selected]
		newSelected.push(option)
		setSelected(newSelected)
		onChange(newSelected)
	}

	const removeOption = (option) => {
		let newSelected = []

		for ( var i = 0; i<selected.length; i++ )
		{
			if ( selected[i] !== option )
			{
				newSelected.push(selected[i])
			}
		}

		setSelected(newSelected)
		onChange(newSelected)
	}

	const isSelected = (option) => {
		for ( var i = 0; i<selected.length; i++ )
		{
			if ( selected[i] === option )
			{
				return true
			}
		}

		return false
	}

	return (
		<div className="DietarySelector">

			{OPTIONS.map((item, index) => {
				return (
					<label key={index} className="DietarySelector__item">
						<div className="DietarySelector__item__checkbox">
							<input onChange={(e) => {

								if ( e.target.checked )
								{
									addOption(item)
								}
								else
								{
									removeOption(item)
								}
																
							}} checked={isSelected(item)} type="checkbox"/>
						</div>
						{item}
					</label>
				)
			})}

			<label className="DietarySelector__item">
				<div className="DietarySelector__item__checkbox">
					<input onChange={(e) => {

						setOtherSelected(e.target.checked)

						if ( e.target.checked === false )
						{
							removeOption(other)
						}
														
					}} checked={otherSelected} type="checkbox"/>
				</div>
				Other
			</label>

			{ otherSelected &&
                <div className="form__field">
                <label className="form__field__label">Other Dietary Requirements</label>
                    <textarea onChange={(e) => {
                        setOther(e.target.value)
                    }} onBlur={(e) => {
                        addOption(e.target.value)
                    }} value={other} className="form__field__input" style={{width: '200px'}} placeholder="Enter dietary information"/>
                </div>
            }

		</div>
	)
}


DietarySelector.propTypes = {
	onChange: PropTypes.func.isRequired
}

export default DietarySelector