// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';


// Components
import Button from 'components/UI/Button';
import Switch from 'components/UI/Switch';


// Assets
import "react-datepicker/dist/react-datepicker.css";


const TicketTypeForm = props => {

	// Props
	const { onSubmit, errorMessage, values} = props;


	// State
	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [limitPerOrder, setLimitPerOrder] = useState(0);
	const [capacity, setCapacity] = useState(0);
	const [availableFrom, setAvailableFrom] = useState();
	const [availableTo, setAvailableTo] = useState();
	const [collectNames, setCollectNames] = useState(false);
	const [useUserNameOnFirstTicket, setUseUserNameOnFirstTicket] = useState(false);
	const [enabled, setEnabled] = useState(true);

	useEffect(() => {
		if ( values )
		{
			setName(values.name);
			setPrice(values.price);
			setCapacity(values.capacity);
			setLimitPerOrder(values.limit_per_order);
			setCollectNames(values.collect_names);
			setEnabled(values.enabled);
			setUseUserNameOnFirstTicket(values.user_name_on_first_ticket);

			if ( values.available_from )
			{
				setAvailableFrom(new Date(values.available_from));
			}

			if ( values.available_to )
			{
				setAvailableTo(new Date(values.available_to));
			}
		}
	}, [values]);


	return (


		<form onSubmit={(e) => {

			e.preventDefault();

			onSubmit({
				name,
				enabled: enabled,
				price,
				capacity: capacity !== '' ? Number(capacity) : '',
				limit_per_order: limitPerOrder !== '' ? Number(limitPerOrder) : '',
				available_from: availableFrom ? dayjs(availableFrom).format('YYYY-MM-DD HH:mm') : '',
				available_to: availableTo ? dayjs(availableTo).format('YYYY-MM-DD HH:mm') : '',
				collect_names: collectNames,
				user_name_on_first_ticket: useUserNameOnFirstTicket
			});

		}} className="form">

			{ errorMessage &&
				<div className="form__error">
					{errorMessage}
				</div>
			}

			<div className="form__body">

				<Switch onChange={(value) => {
					setEnabled(value);
				}} label="Enabled" defaultValue={enabled}/>
	
				<div className="form__field">
					<label className="form__field__label">Ticket Name</label>
					<input onChange={(e) => {
						setName(e.target.value);
					}} value={name} className="form__field__input"/>
				</div>

				<div className="form__field">
					<label className="form__field__label">Price</label>
					<input onChange={(e) => {
						setPrice(e.target.value);
					}} value={price} className="form__field__input"/>
				</div>

				<div className="form__field" style={{display: 'flex'}}>
					<div style={{marginRight: '20px'}}>
					<label className="form__field__label">Available from</label>
					<DatePicker dateFormat="yyyy-MM-dd HH:mm" showTimeSelect onChange={(date) => {
						setAvailableFrom(date);
					}} selected={availableFrom} className="form__field__input form__field__input--short"/>
	
					</div>
					<div>
					<label className="form__field__label">Available to</label>
					<DatePicker dateFormat="yyyy-MM-dd HH:mm" showTimeSelect onChange={(date) => {
						setAvailableTo(date);
					}} selected={availableTo} className="form__field__input form__field__input--short"/>

					</div>
				</div>


				<div className="form__field" style={{display: 'flex'}}>
					<div style={{marginRight: '20px'}}>
						<label className="form__field__label">Limit per order</label>
						<input onChange={(e) => {
							setLimitPerOrder(e.target.value);
						}} value={limitPerOrder} className="form__field__input form__field__input--tiny"/>

					</div>
					<div>
						<label className="form__field__label">Total Capacity</label>
						<input onChange={(e) => {
							setCapacity(e.target.value);
						}} value={capacity} className="form__field__input form__field__input--tiny"/>

					</div>
				</div>


				<Switch onChange={(value) => {
					setCollectNames(value);
				}} label="Collect names for ticket" defaultValue={collectNames}/>

				<Switch onChange={(value) => {
					setUseUserNameOnFirstTicket(value);
				}} label="Use User's name on the first ticket" defaultValue={useUserNameOnFirstTicket}/>
				

		

			</div>

			<div className="form__footer">
				<Button label="Save Ticket Type"/>
			</div>

		</form>
	);
}


TicketTypeForm.defaultProps = {
	errorMessage: '',
	values: {}
}

TicketTypeForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
	values: PropTypes.object
}

export default TicketTypeForm;