// Packages
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'
import DietarySelector from 'components/DietarySelector'

// Other
import Api from 'other/EventsApi'



const OrdersTicketNamesScreen = () => {

	// URL Params
	const { eventId, orderId } = useParams();


	// State
	const [ order, setOrder ] = useState([]);
	const [ orderLoading, setOrderLoading ] = useState(true);

	const [ saving, setSaving ] = useState(false)

	const [ names, setNames ] = useState([]);
	const [ diet, setDiet ] = useState([]);
	const [ allergies, setAllergies ] = useState([]);
	const [ accessibility, setAccessibility ] = useState([]);


	const navigate = useNavigate()


	useEffect(() => {
		Api.orders.get(orderId, {
			success: (response) => {
				setOrder(response.data);

				let names = []
				let diet = []
				let allergies = []
				let accessibility = []

				for ( var i=0; i<response.data.tickets.length; i++ )
				{
					names[i] = response.data.tickets[i].attendee_name ?? ''
					diet[i] = response.data.tickets[i].dietary_requirements ?? []
					allergies[i] = response.data.tickets[i].allergies ?? ''
					accessibility[i] = response.data.tickets[i].accessibility_requirements ?? ''
				}

				setNames(names)
				setDiet(diet)
				setAllergies(allergies)
				setAccessibility(accessibility)
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setOrderLoading(false);
			}
		})
	}, [orderId]);



	const saveNames = () => {

        let tickets = []

        for ( var i=0; i<names.length; i++ )
        {
            tickets.push({
            	...order.tickets[i], 
            	attendee_name: names[i] ? names[i] : '',
            	dietary_requirements: diet[i] ? diet[i]: [],
            	allergies: allergies[i],
            	accessibility_requirements: accessibility[i]
            })
        }


        Api.orders.update(orderId, {
        	before: () => {
        		setSaving(true)
        	},
        	data: {
        		tickets
        	},
        	success: (response) => {
        		navigate(`/orders/${eventId}/${orderId}`)
        	},
        	error: (error) => {
        		alert('Error')
        	},
        	after: () => {
        		setSaving(false)
        	}
        })


	}
	


	if ( orderLoading )
	{
		return (
			<Loading message="Loading order"/>
		)
	}

	return (
		<>
		<ScreenTitle title={`Ticket Names For Order ${order.id}`}/>


  		<Accordian icon="tickets" title="Tickets" open={true}>

  			<table className="table">
  			<thead>
  			<tr>
  				<th>#</th>
  				<th>Ticket</th>
  				<th>Name</th>
  				<th>Dietary Requirements</th>
  				<th>Allergies</th>
  				<th>Accessibility Requirements</th>
  			</tr>
  			</thead>
  			<tbody>

  			{order.tickets.map((ticket, ticketIndex) => {


  				let inputDisabled = ticket.status && ticket.status === 'refunded'

  				return (
  					<tr className={ticket.status === 'refunded' ? 'red': ''} key={`ticketIndex-${ticketIndex}`}>
  						<td>{ticketIndex+1}</td>
  						<td>
  							<b>{ticket.ticket_type.name}</b><br/>
  							{ticket.event.name}
  						</td>
  						<td>
  							<input onChange={(e) => {

								let newNames = [...names];
                             	newNames[ticketIndex] = e.target.value;
                             	setNames(newNames);

							}}  disabled={inputDisabled}  className="form__field__input" style={{width: '250px'}} value={names[ticketIndex] ? names[ticketIndex] : ''}/>
  						</td>
  						<td>
  							<DietarySelector values={diet[ticketIndex]??[]} onChange={(values) => {
  								let newDiet = [...diet];
                             	newDiet[ticketIndex] = values;
  								setDiet(newDiet)
  							}}/>
  						</td>
  						<td>
  							<textarea disabled={inputDisabled} onChange={(e) => {
  								let newAllergies = [...allergies];
                             	newAllergies[ticketIndex] = e.target.value;
  								setAllergies(newAllergies)
  							}} value={allergies[ticketIndex]} className="form__field__input" style={{width: '200px'}} rows="3"/>
  						</td>
  						<td>
  							<textarea disabled={inputDisabled} onChange={(e) => {
  								let newAccessibility = [...accessibility];
                             	newAccessibility[ticketIndex] = e.target.value;
  								setAccessibility(newAccessibility)
  							}} value={accessibility[ticketIndex]} className="form__field__input" style={{width: '200px'}} rows="3"/>
  						</td>
  					</tr>
  				)

  			})}

  			</tbody>

  			
  			</table>

  			{ saving &&
  				<Loading message="Saving ticket details"/>
  			}

  			{ ! saving &&
  				<Button onClick={saveNames} label="Save Ticket Details"/>
  			}

  		</Accordian>


  		</>
	)
}

export default OrdersTicketNamesScreen;