// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';


// Assets
import './assets/styles.css';


const Switch = props => {

    // Props
    const { label, defaultValue, onChange } = props;


    // State
    const [ value, setValue ] = useState(false);


    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);


    return (
        <div onClick={() => {
                let newValue = ! value;
                setValue(newValue);
                onChange(newValue)
            }} className={`Switch Switch--${value===true ? 'on' : 'off'} `}>
            <div className="Switch__switch">
                <div className={`Switch__switch__handle`}/>
            </div>
            <div className="Switch__label">
                {label}
            </div>
        </div>
    );
}

Switch.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default Switch;