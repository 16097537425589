// Packages
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'
import Form from 'components/Events/Venues/Form'


// Other
import Api from 'other/EventsApi'



const EventVenueEditScreen = () => {

	// URL Parameters
	const { venueId } = useParams();


	// State
	const [ venue, setVenue ] = useState([]);
	const [ venueLoading, setVenueLoading ] = useState(true);

	const [ saving, setSaving ] = useState(false);

	const [error, setError] = useState();

	const navigate = useNavigate();


	useEffect(() => {

		Api.venues.get(venueId, {
			success: (response) => {
				setVenue(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setVenueLoading(false);
			}
		});

		
	}, [venueId]);


	const onSubmit = (values) => {

		Api.venues.update(venueId, {

			data: values,

			before: () => {
				setSaving(true);
			},
			successRedirect: (response) => {
				navigate('/venues');
			},
			error: (error) => {
				setError(error.error);
			},
			after: () => {
				setSaving(false);
			},
		})

	}

	const onDelete = () => {
		Api.venues.delete(venueId, {
			successRedirect: () => {
				navigate('/venues');
			},
			error: (error) => {
				alert('Error deleting venue');
			}

		});
	}



	if ( venueLoading )
	{
		return <Loading message="Loading Venue"/>
	}
	

	return (
		<>
		<ScreenTitle title={venue.name}/>



  		<Accordian icon="events" title="Venues" open={true}>

  			<Form values={venue} errorMessage={error} onSubmit={(values) => {
				onSubmit(values);
			}}/>

		{ saving &&
			<>
			<br/>
			<Loading message="Saving Venue..."/>
			</>
		}



		<Button onClick={() => {

			if ( window.confirm('Really delete this venue?') )
			{
				onDelete();
			}


		}} colour="red" style={{marginTop: '50px'}} label="Delete Venue"/>
  			
  			
  			
  		</Accordian>



  		</>
	)
}

export default EventVenueEditScreen;