// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'


// Other
import Api from 'other/EventsApi'



const OrdersRefundScreen = () => {

	// URL Params
	const { orderId } = useParams()


	// State
	const [ order, setOrder ] = useState([])
	const [ orderLoading, setOrderLoading ] = useState(true)

	const [refundTicketIds, setRefundTicketIds] = useState([])
	const [refundLoading, setRefundLoading] = useState(false)


	const processRefund = () => {
		Api.orders.createRefund(orderId, {
			data: {
				tickets: refundTicketIds
			},
			before: () => {
				setRefundLoading(true)
			},
			success: (response) => {

			},
			error: (error) => {

			},
			after: () => {
				setRefundLoading(false)
			}
		})
	}

	const addOption = (option) => {
		let newIds = [...refundTicketIds]
		newIds.push(option)
		setRefundTicketIds(newIds)
	}

	const removeOption = (option) => {
		let newIds = []

		for ( var i = 0; i<refundTicketIds.length; i++ )
		{
			if ( refundTicketIds[i] !== option )
			{
				newIds.push(refundTicketIds[i])
			}
		}

		setRefundTicketIds(newIds)
	}


	useEffect(() => {
		Api.orders.get(orderId, {
			success: (response) => {
				setOrder(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setOrderLoading(false);
			}
		})
	}, [orderId]);
	


	if ( orderLoading )
	{
		return (
			<Loading message="Loading order"/>
		)
	}

	return (
		<>
		<ScreenTitle title={`Start Refund ${order.id}`}/>

  		<Accordian icon="tickets" title="Tickets" open={true}>

  			<form onSubmit={(e) => {
  				e.preventDefault()
  			}}>

  			<table className="table">
  			<thead>
  			<tr>
  				<th>#</th>
  				<th>Ticket</th>
  				<th>Name</th>
  				<th className="r">Price</th>
  				<th className="c">Refund</th>
  			</tr>
  			</thead>
  			<tbody>

  			{order.tickets.map((ticket, index) => {

  				return (
  					<tr>
  						<td>{index+1}</td>
  						<td>
  							<b>{ticket.ticket_type.name}</b><br/>
  							{ticket.event.name}
  						</td>

  						<td>{ticket.attendee_name}</td>
  						<td className="r">£ {Number(ticket.ticket_type.price).toFixed(2)}</td>
  						<td className="c">

  							{ ticket.status !== 'refunded' &&
  							
	  							<input onChange={(e) => {

	  								if ( e.target.checked )
									{
										addOption(index)
									}
									else
									{
										removeOption(index)
									}

	  							}} type="checkbox"/>

	  						}

  						</td>
  					</tr>
  				)

  			})}

  			</tbody>
  			</table>

  			{ refundLoading &&
  				<Loading message="Processing Refund"/>
  			}

  			{ refundTicketIds.length > 0 &&
	  			<div style={{textAlign: 'center', margin: '40px 0 20px'}}>
	  				<Button onClick={processRefund} label="Process Refund" colour="orange"/>
	  			</div>
	  		}

  			</form>

  		</Accordian>



  		</>
	)
}

export default OrdersRefundScreen;