// Packages
import PropTypes from 'prop-types';


// Components
import ListItem from 'components/Events/Venues/ListItem';

const VenueList = props => {

    // Props
    const { items } = props;

    return (
        <table className="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Address</th>
			</tr>
			</thead>
			<tbody>


            {items.map((item) => {

                return (
                    <ListItem key={item.id} {...item}/>
                );

            })}

            </tbody>

        </table>
    );
}


VenueList.propTypes = {
    items: PropTypes.array.isRequired
}

export default VenueList;