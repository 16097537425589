// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { name, address, id } = props;

	return (
		<tr>
			<td><Link to={`/venues/${id}`}>{name}</Link></td>
			<td>{address}</td>
		</tr>
	);
}


ListItem.propTypes = {
	address: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
}

export default ListItem;