// Packages
import PropTypes from 'prop-types';


// Components
import ListItem from 'components/Events/Orders/TransactionListItem';


const OrderTransactionList = props => {

    // Props
    const { items } = props;

    return (
        <table className="table">
			<thead>
			<tr>
                <th>Date</th>
                <th>Gateway</th>
                <th>Transaction #</th>
                <th className="r">Gross</th>
                <th className="r">Gateway Fee</th>
                <th className="r">Net Received</th>
                <th>Type</th>
            </tr>
			</thead>
			<tbody>


            {items.map((item) => {

                return (
                    <ListItem key={item.id} {...item}/>
                );

            })}

            </tbody>

        </table>
    );
}


OrderTransactionList.propTypes = {
    items: PropTypes.array.isRequired
}

export default OrderTransactionList;