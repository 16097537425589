// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { id, name, code, type, value } = props;

	return (
		<tr>
			<td><Link to={`/coupons/${id}`}>{name}</Link></td>
			<td>{code}</td>
			<td>{type}</td>
			<td>{value}</td>
		</tr>
	);
}


ListItem.propTypes = {
	code: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
}


export default ListItem;