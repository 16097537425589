// Packages
import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'



// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import Loading from 'components/UI/Loading'
import InfoTable from 'components/UI/InfoTable'
import SendRefundEmail from 'components/Events/Refunds/SendRefundEmail'
import TransactionList from 'components/Events/Orders/TransactionList'


// Other
import Api from 'other/EventsApi'



const RefundsViewScreen = () => {

	// URL Params
	const { refundId } = useParams();


	// State
	const [ refund, setRefund ] = useState([]);
	const [ refundLoading, setRefundLoading ] = useState(true);

	useEffect(() => {
		Api.refunds.get(refundId, {
			success: (response) => {
				setRefund(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setRefundLoading(false);
			}
		})

	}, [refundId]);
	


	if ( refundLoading )
	{
		return (
			<Loading message="Loading refund"/>
		)
	}

	return (
		<>
		<ScreenTitle title={`Refund ${refund.id}`}/>

  		<Accordian icon="user" title="Refund Overview" open={true}>
  				
  			<InfoTable.Table>

  				<InfoTable.Row>
					<InfoTable.Column label="Refund #" value={refund.id}/>
					<InfoTable.Column label="Refund Date" value={dayjs(refund.created_at).format('DD MMM YYYY HH:mm')}/>
				</InfoTable.Row>

				<InfoTable.Row>
					<InfoTable.Column label="Order #">
						<Link to={`/orders/${refund.event_id}/${refund.order_id}`}>{refund.order_id}</Link>
					</InfoTable.Column>
				</InfoTable.Row>

				<InfoTable.Row>
					<InfoTable.Column label="Ambassador Name" value={refund.ambassador.firstname + ' ' + refund.ambassador.lastname}/>
					<InfoTable.Column label="Ambassador #" value={refund.ambassador.id}/>
				</InfoTable.Row>
				<InfoTable.Row>
					<InfoTable.Column label="Email" value={refund.ambassador.email}/>

				</InfoTable.Row>


  			</InfoTable.Table>
  			
  		</Accordian>


  		<Accordian icon="tickets" title="Refunded Tickets" open={true}>

  			<table className="table">
  			<thead>
  			<tr>
  				<th>#</th>
  				<th>Ticket</th>
  				<th>Attendee Name</th>
  				<th className="r">Price</th>
  			</tr>
  			</thead>
  			<tbody>

  			{refund.tickets.map((ticket, index) => {

  				return (
  					<tr key={index}>
  						<td style={{width: '40px'}}>{index+1}</td>
  						<td>
  							<b>{ticket.ticket_type.name}</b><br/>
  							{ticket.event.name}
  						</td>
  						<td>{ticket.attendee_name}</td>
  						<td className="r">£ {Number(ticket.ticket_type.price).toFixed(2)}</td>
  					</tr>
  				)

  			})}

  			</tbody>

  			<tfoot>

  			{ refund.coupon &&
  				<>
  					<tr>
	  					<td colSpan="3" className="r"><b>Sub-Total:</b></td>
	  					<td className="r"><b>£ {Number(refund.sub_total).toFixed(2)}</b></td>
	  				</tr>

  					<tr>
	  					<td colSpan="3" className="r"><b>Coupon {refund.coupon.code} Discount:</b></td>
	  					<td className="r"><b>£ {Number(refund.discount).toFixed(2)}</b></td>
	  				</tr>

  				</>
  			}
  			
  			<tr>
  				<td colSpan="3" className="r"><b>Total:</b></td>
  				<td className="r"><b>£ {Number(refund.total).toFixed(2)}</b></td>
  			</tr>
  			<tr style={{color: '#76B82A'}}>
  				<td colSpan="3" className="r"><b>Total Refunded:</b></td>
  				<td className="r"><b>£ {Number(refund.total_paid).toFixed(2)}</b></td>
  			</tr>
  			<tr style={{color: '#BC2354'}}>
  				<td colSpan="3" className="r"><b>Total Due:</b></td>
  				<td className="r"><b>£ {Number(refund.total_due).toFixed(2)}</b></td>
  			</tr>
  			</tfoot>

  			</table>


  		</Accordian>


  		<Accordian icon="financial" title="Financial Transactions">

  			{ refund.transactions &&
				<TransactionList items={refund.transactions}/>
			}

  		</Accordian>


  		<Accordian icon="email" title="Resend Email">
  			<p>Use this feature to resend the initial refund acknowledgement email. If the Ambassador is having problems with their primary email account, then you can enter an alternative email address in the box below.</p>
	  		<SendRefundEmail email={refund.ambassador.email} refundId={refund.id}/>
  		</Accordian>

  		</>
	)
}

export default RefundsViewScreen;