// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import Loading from 'components/UI/Loading';
import InfoTable from 'components/UI/InfoTable';
import Button from 'components/UI/Button';


// Other
import EventsApi from 'other/EventsApi';



const EventsViewScreen = () => {

	// URL Parameters
	const { eventId } = useParams();


	// State
	const [ event, setEvent ] = useState([]);
	const [ loading, setLoading ] = useState(true);


	const TABS = [
		{ label: 'Overview', url: '/events/' + eventId, active: true},
		{ label: 'Sales', url: '/events/' + eventId + '/orders'},
		{ label: 'Tickets', url: '/events/' + eventId + '/tickets'},
		{ label: 'Hotels', url: '/events/' + eventId + '/hotels'},
		{ label: 'FAQs', url: '/events/' + eventId + '/faqs'},
		{ label: 'Landing Page', url: '/events/' + eventId + '/page'}
	];


	useEffect(() => {
		EventsApi.events.get(eventId, {
			success: (response) => {
				setEvent(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		})
	}, [eventId]);



	if ( loading )
	{
		return <Loading message="Loading Event"/>
	}
	

	return (
		<>
		<ScreenTitle title={event.name}>
			<Button url={`/events/${event.id}/edit`} label="Edit Event"/>
		</ScreenTitle>



  		<Accordian icon="events" title="Overview" open={true} tabs={TABS}>
  				
  			<InfoTable.Table>

				<InfoTable.Row>
					<InfoTable.Column label="Name" value={event.name}/>
					<InfoTable.Column label="Venue" value={event.venue.name}/>
				</InfoTable.Row>

				<InfoTable.Row>
					<InfoTable.Column label="Date" value={event.date}/>
					<InfoTable.Column label="Time" value={event.time}/>
				</InfoTable.Row>


				<InfoTable.Row>
					<InfoTable.Column label="Total Capacity" value={event.capacity}/>
					<InfoTable.Column label="Total Sold" value="xx"/>
				</InfoTable.Row>

  			</InfoTable.Table>
  			
  		</Accordian>



  		</>
	)
}

export default EventsViewScreen;