// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'


// Components
import ScreenTitle from 'components/UI/ScreenTitle'
import Accordian from 'components/UI/Accordian'
import List from 'components/Events/Faqs/List'
import Loading from 'components/UI/Loading'
import Button from 'components/UI/Button'


// Other
import Api from 'other/EventsApi'



const EventFaqsScreen = () => {

	// URL Parameters
	const { eventId } = useParams();


	// State
	const [ event, setEvent ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const [ faqs, setFaqs] = useState([]);
	const [ faqsLoading, setFaqsLoading] = useState(true);


	const TABS = [
		{ label: 'Overview', url: '/events/' + eventId},
		{ label: 'Sales', url: '/events/' + eventId + '/orders'},
		{ label: 'Tickets', url: '/events/' + eventId + '/tickets'},
		{ label: 'Hotels', url: '/events/' + eventId + '/hotels'},
		{ label: 'FAQs', url: '/events/' + eventId + '/faqs', active: true},
		{ label: 'Landing Page', url: '/events/' + eventId + '/page'}
	];


	useEffect(() => {
		Api.events.get(eventId, {
			success: (response) => {
				setEvent(response);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setLoading(false);
			}
		});

		Api.faqs.list({
			params: {
				eventId: eventId
			},
			success: (response) => {
				setFaqs(response.data);
			},
			error: (error) => {
				alert('Error: ' + error);
			},
			after: () => {
				setFaqsLoading(false);
			}
		});
	}, [eventId]);



	if ( loading )
	{
		return <Loading message="Loading FAQs"/>
	}
	

	return (
		<>
		<ScreenTitle title={event.name}/>



  		<Accordian icon="events" title="FAQs" open={true} tabs={TABS}>

  			<Button url={`/events/${eventId}/faqs/create`} label="New FAQ"/>
  				
  			{ faqsLoading &&
  				<Loading message="Loading FAQs"/>
  			}

  			{ ! faqsLoading &&
  				<List items={faqs}/>
  			}
 			
  			
  		</Accordian>



  		</>
	)
}

export default EventFaqsScreen;