// Packages
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';


// Components
import ProgressBar from 'components/ProgressBar';


// Other
import Api from 'other/EventsApi';


// Assets
import './assets/styles.css';


const ImageUpload = props => {

	// Props
	const { onChange, thumbnail } = props;


	// State
	const [ thumbnailUrl, setThumbnailUrl ] = useState('');
    const [ uploading, setUploading ] = useState(false);
    const [ uploadProgress, setUploadProgress] = useState(0);

	useEffect(() => {
		setThumbnailUrl(thumbnail);
	}, [thumbnail]);





	const uploadFile = (event) => {

		const file = event.target.files[0];

        setUploading(true);

		Api.uploadFile(file, {

			success: (response) => {
                setThumbnailUrl(response.publicUrl);
                onChange(response.publicUrl);
                setUploading(false)
            },
			error: (error) => {
				console.log('Error: ' + error.message);
			},
            progress: (progress) => {
                setUploadProgress(progress);
            }
		});
	}






	return (
		<div className="ThumbnailUploader">

            { uploading &&
                <div className="ThumbnailUploader__uploading">
                    <ProgressBar value={uploadProgress}/>
                </div>
            }


            { ! uploading &&
                <>
    			<div className={`ThumbnailUploader__thumbnail`} style={{
    				backgroundImage: "url('" + thumbnailUrl + "')"
    			}}/>

    			<input onChange={uploadFile} type="file" name="file"/>
                </>
            }

   		</div>
	);
}

ImageUpload.propTypes = {
	onChange: PropTypes.func.isRequired
}

ImageUpload.defaultProps = {
	onChange: () => { alert('No onChange property set!') }
}

export default ImageUpload;