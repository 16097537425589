// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ListItem = props => {

	// Props
	const { id, name, miles_to_venue, address, event_id: eventId } = props;

	return (
		<tr>
			<td><Link to={`/events/${eventId}/hotels/${id}`}>{name}</Link></td>
			<td>{miles_to_venue} miles</td>
			<td>{address}</td>
		</tr>
	);
}


ListItem.propTypes = {
	address: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	miles_to_venue: PropTypes.string.isRequired,
	event_id: PropTypes.string.isRequired
}

export default ListItem;